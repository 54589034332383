<template>
  <div>
    <v-card-title>Update Username</v-card-title>
    <v-divider />
    <form @submit.prevent="updateUsername">
      <v-row>
        <v-col cols="4">
          <v-subheader>Username</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field
            label="username"
            v-model="usernameFormData.username"
            :error-messages="usernameErrorMessages.msg"
          />
        </v-col>
      </v-row>
      <v-row
        class="justify-end"
        no-gutters
      >
        <v-btn
          type="submit"
          color="primary"
          :disabled="loading || usernameFormData.username === user.username"
        >
          Update
        </v-btn>
      </v-row>
    </form>
  </div>
</template>

<script>
export default {
  name: "UsernameForm",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      usernameFormData: {
        username: this.user.username
      },
      loading: false,
      usernameErrorMessages: {}
    }
  },
  methods: {
    async updateUsername () {
      this.loading = true;
      const response = await this.$store.dispatch("updateUsernameAction", {
        userEmail: this.user.email,
        data: {...this.usernameFormData}
      });
      if (response && response.status === 200) {
        this.usernameErrorMessages = []
        this.$ebus.$emit("newToastMessage", {
          message: "Username updated successfully.",
        });
        this.loading = false;
      } else {
        this.usernameErrorMessages = response.data
        this.loading = false;
        this.$ebus.$emit("newToastMessage", {
          message: "Failed to update username.",
          color: "danger",
        });
      }
    }
  },
  watch: {
    'user.id' () {
      this.usernameFormData.username = JSON.parse(JSON.stringify(this.user.username))
    },
  }
}
</script>

<style scoped>

</style>