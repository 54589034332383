<template>
  <div>
    <v-card-title>Update Password</v-card-title>
    <v-divider />
    <form @submit.prevent="updatePassword">
      <v-row>
        <v-col cols="4">
          <v-subheader>Current Password</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field
            required
            type="password"
            label="Current Password"
            v-model="formData.old_password"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-subheader>New Password</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field
            required
            type="password"
            label="New Password"
            v-model="formData.new_password"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-subheader>Confirm New Password</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field
            required
            type="password"
            label="Confirm New Password"
            v-model="formData.confirm_new_password"
          />
        </v-col>
      </v-row>
      <p
        class="white--text red pa-2 text-center"
        v-if="errorMessages && errorMessages.msg"
      >
        {{ errorMessages.msg }}
      </p>
      <v-row
        class="justify-end"
        no-gutters
      >
        <v-btn
          type="submit"
          color="primary"
          :disabled="loading"
        >
          Update
        </v-btn>
      </v-row>
    </form>
  </div>
</template>

<script>

export default {
  name: "UserPasswordUpdateForm",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formData: {},
      loading: false,
      errorMessages: {}
    }
  },
  methods: {
    async updatePassword () {
      this.loading = true;
      const response = await this.$store.dispatch("userPasswordUpdate", {
        email: this.user.email,
        data: {...this.formData}
      });
      if (response && response.status === 200) {
        this.errorMessages = {}
        this.$ebus.$emit("newToastMessage", {
          message: "Password updated successfully.",
        });
        this.loading = false;
        this.$store.dispatch("triggerLogout")
      } else {
        this.errorMessages = response.data
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>