<template>
  <v-card class="profile-card">
    <v-img
      class="profile-photo rounded-circle"
      width="150"
      height="150"
      :alt="user.name"
      :src="user.profile_photo ? `${baseImageUrl}?path=${user.profile_photo}` : ''"
    />
    <v-card-title class="justify-center">
      {{ user.name }}
    </v-card-title>
    <key-value-pair-data
      :data="user"
      :date-time-fields="dateTimeFields"
      :ignored-fields="ignoredFieldsToShow"
    />
  </v-card>
</template>

<script>
import { BASE_IMAGE_URL } from "@/utils/constants";
import KeyValuePairData from '../common/KeyValuePairData.vue';

export default {
  components: { KeyValuePairData },
  name: "UserProfile",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ignoredFieldsToShow: ["id", "profile_photo", "invitation_left"],
      dateTimeFields: ["created_at", "updated_at"],
      baseImageUrl: BASE_IMAGE_URL
    }
  },
}
</script>

<style lang="scss" scoped>
.profile-card {
  padding: 10px 0 0 0;
  .profile-photo {
    margin: 0 auto;
  }
}
</style>
