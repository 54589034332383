<template>
  <v-card
    class="user-update-form"
    :loading="loading"
  >
    <v-card-title>Update Basic Information</v-card-title>
    <v-divider />
    <form @submit.prevent="updateUserData">
      <v-row>
        <v-col cols="4">
          <v-img
            v-if="profileImageFile"
            class="profile-photo rounded-circle"
            width="75"
            height="75"
            :src="imagePreviewUrl"
          />
          <v-img
            v-if="!profileImageFile"
            class="profile-photo rounded-circle"
            width="75"
            height="75"
            :src="`${baseImageUrl}?path=${user.profile_photo}`"
          />
        </v-col>
        <v-col cols="8">
          <v-file-input
            show-size
            accept="image/*"
            label="Profile Photo"
            v-model="profileImageFile"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-subheader>Full Name</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field
            label="Name"
            v-model="userFormData.name"
          />
        </v-col>
      </v-row>
      <v-alert
        closable
        type="error" 
        v-if="basicInfoErrorMsg"
      >
        {{ basicInfoErrorMsg }}
      </v-alert>
      <v-row 
        class="justify-end" 
        no-gutters
      >
        <v-btn
          type="submit"
          color="primary"
          :disabled="loading"
        >
          Update
        </v-btn>
      </v-row>
    </form>

    <username-form :user="user" />
    <user-password-update-form :user="user" />
  </v-card>
</template>

<script>
import { BASE_IMAGE_URL } from "@/utils/constants"
import UsernameForm from "@/components/user/UsernameForm";
import UserPasswordUpdateForm from "@/components/user/UserPasswordResetForm";

export default {
  name: "UserUpdate",
  components: {UserPasswordUpdateForm, UsernameForm},
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userFormData: {},
      baseImageUrl: BASE_IMAGE_URL,
      profileImageFile: null,
      loading: false,
      imagePreviewUrl: null,
      basicInfoErrorMsg: null,
    }
  },
  mounted () {
    this.userFormData = JSON.parse(JSON.stringify(this.user))
  },
  watch: {
    'user.id' () {
      this.userFormData = JSON.parse(JSON.stringify(this.user))
    },
    profileImageFile (newValue) {
      if (newValue !== null) {
        this.imagePreviewUrl = URL.createObjectURL(newValue)
      }
    }
  },
  methods: {
    async updateUserData () {
      this.basicInfoErrorMsg = null
      this.loading = true
      let relativePath = this.user.profile_photo
      // If image file changed then,
      if (this.profileImageFile) {
        const imageUploadResp = await this.$store.dispatch("uploadImage", this.profileImageFile);
        if (imageUploadResp && imageUploadResp.status === 200) {
          this.profileImageFile = null
          relativePath = imageUploadResp.data['relative_path']
          this.$ebus.$emit("newToastMessage", {
            message: "Image upload successful.",
          });
        } else {
          this.basicInfoErrorMsg = imageUploadResp.msg
        }
      }
      // Otherwise Just update with existing data
      const response = await this.$store.dispatch("updateUserInfomationAction", {
        userEmail: this.user.email,
        data: {...this.userFormData, profile_photo: relativePath}
      });
      if (response && response.status == 200) {
        this.$ebus.$emit("newToastMessage", {
          message: "User profile updated successfully.",
        });
        this.loading = false;
      } else {
        this.loading = false;
        this.$ebus.$emit("newToastMessage", {
          message: "Failed to update user profile.",
          color: "danger",
        });
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.user-update-form {
  padding: 8px 1rem 8px 1rem;
  form {
    margin-bottom: 1rem;
  }
  .v-card__title {
    padding: 0;
  }
  .v-divider {
      margin-bottom: 1rem;
  }
}

</style>