<template>
  <div>
    <login-user-landing />
  </div>
</template>

<script>
import LoginUserLanding from '../components/user/LoginUserLanding.vue'
export default {
  components: { LoginUserLanding },
  name: "LoginUserLandingView"
}
</script>