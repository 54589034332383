<template>
  <v-row dense>
    <v-col
      cols="12"
      md="4"
      sm="6"
    >
      <user-profile :user="loginUserInfo" />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="8"
    >
      <user-update-form :user="loginUserInfo" />
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex'
import UserProfile from './UserProfile.vue'
import UserUpdateForm from './UserUpdateForm.vue'

export default {
  components: { UserProfile, UserUpdateForm },
  name: "LoginUserLanding",
  computed: {
    ...mapGetters(["loginUserInfo"])
  },
  mounted () {
    this.$route.meta.title = this.loginUserInfo ? `${this.loginUserInfo.name} | AmarDiary` : 'My Profile | AmarDiary'
  }
}
</script>